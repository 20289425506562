import React, { useState, useRef, useEffect } from "react";
import { FaSmile, FaFrown } from 'react-icons/fa';
import "./App.css";

const App = () => {
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [answerLink, setAnswerLink] = useState("");
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [userFeedback, setUserFeedback] = useState("");
  const [feedbackFormVisible, setFeedbackFormVisible] = useState(false);
  const chatHistoryRef = useRef(null);
  const feedbackRef = useRef(null);

  const mockDataList = [
    {
      answer: "This is the main answer to your question.",
      question: "What is the API?",
      link: "https://example.com/api"
    },
    {
      answer: "The API works by allowing you to access data through HTTP requests.",
      question: "How does the API work?",
      link: "https://example.com/how-api-works"
    },
    {
      answer: "An API key is a unique identifier used to authenticate requests.",
      question: "What is the API key?",
      link: "https://example.com/api-key"
    }
  ];

  const handleSubmit = () => {
    if (inputValue.trim() === "") return;

    const firstData = mockDataList[0];
    setCurrentQuestion(firstData.question);
    setAnswer(firstData.answer);
    setAnswerLink(firstData.link);
    setSuggestedQuestions(mockDataList.slice(1));
    setInputValue("");
    setFeedbackMessage("");
    setSelectedFeedback(null);

    setChatHistory(prevHistory => [
      ...prevHistory,
      { question: inputValue, answer: firstData.answer, link: firstData.link }
    ]);
  };

  const handleQuestionClick = (question) => {
    setInputValue(question);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleFeedback = (isGood) => {
    setSelectedFeedback(isGood ? 'good' : 'bad');
    if (isGood) {
      setFeedbackMessage("Cảm ơn đánh giá của bạn");
    } else {
      setFeedbackMessage("Đang kết nối đến nhân viên CSKH...");
      // setFeedbackFormVisible(true);
      // feedbackRef.current?.focus();
    }
  };

  const handleFeedbackFormSubmit = () => {
    if (userFeedback.trim() === "") return;

    setFeedbackMessage("Cảm ơn phản hồi của bạn!");
    setUserFeedback("");
    setFeedbackFormVisible(false);
  };

  const handleFeedbackKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleFeedbackFormSubmit();
    }
  };

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  return (
    <div className="app-container">
      <h1>Chatbot QA</h1>
      <div className="chat-history">
        {chatHistory.map((entry, index) => (
          <div key={index} className="chat-entry" ref={index === chatHistory.length - 1 ? chatHistoryRef : null}>
            <div className="chat-question">
              <strong>Câu hỏi:</strong> {entry.question}
            </div>
            <div className="chat-answer">
              <strong>Trả lời:</strong> {entry.answer} 
              <div className="chat-link">
                <a href={entry.link} target="_blank" rel="noopener noreferrer">{entry.link}</a>
              </div>            
            </div>
          </div>
        ))}
      </div>
      <div className="input-container">
        <textarea
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Nhập câu hỏi của bạn..."
        />
        <button onClick={handleSubmit}>Gửi</button>
      </div>
      <div className="feedback-container">
        <FaSmile
          className={`feedback-icon smile ${selectedFeedback === 'good' ? 'selected' : ''}`}
          onClick={() => handleFeedback(true)}
          title="Tốt"
        />
        <FaFrown
          className={`feedback-icon frown ${selectedFeedback === 'bad' ? 'selected' : ''}`}
          onClick={() => handleFeedback(false)}
          title="Kém"
        />
      </div>
      {feedbackMessage && <div className="feedback-message">{feedbackMessage}</div>}

      {suggestedQuestions.length > 0 && (
        <div className="suggested-questions">
          <h2>Câu hỏi gợi ý</h2>
          <ul>
            {suggestedQuestions.map((item, index) => (
              <li key={index}>
                <button onClick={() => handleQuestionClick(item.question)}>{item.question}</button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default App;
